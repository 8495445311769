"use client";

import { FC } from "react";

import { useAppModalsContext } from "@app/auth";
import { GradientButton, useElementOpen } from "@causevest/ui-kit";

import { SuccessDonationModal } from "@features/donation/modals/SuccessDonationModal";

import { useSession } from "@contexts";

import { Campaign } from "@lib/types";

import { TiersListModal } from "./TiersListModal";

interface Props {
  campaign: Partial<Campaign>;
  buttonLabel?: string;
}

export const DonationEmitterBlock: FC<Props> = ({ campaign, buttonLabel = "Donate Now" }) => {
  const { isAuthenticated } = useSession();
  const { setLogInOpen } = useAppModalsContext();
  const { isOpen: areTiersOpen, open: setTiersOpen, close: setTiersClose } = useElementOpen(false);
  const {
    isOpen: isSuccessOpen,
    open: setSuccessOpen,
    close: setSuccessClose,
  } = useElementOpen(false);

  const onInitSupport = () => {
    if (isAuthenticated) {
      setTiersOpen();
    } else {
      setLogInOpen();
    }
  };

  return (
    <>
      <GradientButton sx={{ width: "170px", height: "35px" }} onClick={onInitSupport}>
        {buttonLabel}
      </GradientButton>
      <TiersListModal
        isOpen={areTiersOpen}
        onClose={setTiersClose}
        campaign={campaign}
        current={0}
        setSuccessOpen={setSuccessOpen}
      />
      <SuccessDonationModal campaign={campaign} isOpen={isSuccessOpen} onClose={setSuccessClose} />
    </>
  );
};
