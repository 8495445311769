import { FC } from "react";

import { Icon } from "@causevest/ui-kit";
import { FundsBar } from "@components";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { setDaysGap } from "@lib/helpers";
import common from "@lib/styles/common.module.scss";
import { Campaign } from "@lib/types";

import { DonateBtnsGroup, Fundraisers } from "../";
import { CampaignStatusInput } from "./CampaignStatusInput";
import classes from "./RaiserInner.module.scss";

interface Props {
  campaign: Campaign;
}

export const CampaignMainInfo: FC<Props> = ({ campaign }) => (
  <>
    <Stack flexDirection="row" alignItems="flex-start" className={classes.main}>
      <Stack className={classes.left}>
        <Box className={classes.left__pic}>
          <Icon
            width={300}
            height={300}
            src={campaign.image?.length ? campaign.image : "/images/logo-short.svg"}
            alt="campaign pic"
            style={{ objectFit: "cover" }}
          />
          <Box className={common.badge}>
            <span>{campaign.category?.name}</span>
          </Box>
        </Box>
        <Box className={classes.status}>
          <CampaignStatusInput uuid={campaign.uuid} status={campaign.status} />
        </Box>
      </Stack>
      <Stack className={classes.right}>
        <Stack flexDirection="row" alignItems="center" className={classes.heading}>
          <Typography className={classes.id}>{`CampaignID: ${campaign.uuid}`}</Typography>
          <Box className={clsx(common.badge, common._mobileBadge)}>
            <span>{campaign.category?.name}</span>
          </Box>
        </Stack>
        <Typography variant="h1">{campaign.title}</Typography>
        <Typography className={classes.description}>{campaign.short_description}</Typography>
        {!!campaign.goal && (
          <Box sx={{ mt: "20px" }}>
            <FundsBar goal={campaign.goal} accented />
          </Box>
        )}
        {!!campaign.goal?.ends_at && (
          <Typography
            className={classes.daysLeft}
          >{`${setDaysGap(campaign.goal.ends_at)} days left`}</Typography>
        )}
        <Box sx={{ mt: campaign.goal?.ends_at ? 0 : "20px" }}>
          <Fundraisers campaign={campaign} />
        </Box>
      </Stack>
    </Stack>
    <DonateBtnsGroup campaign={campaign} isRaiserView />
  </>
);
