"use client";

import { FC } from "react";

import { CancelButton, GradientButton, Icon, Preloader, useEffectAsync } from "@causevest/ui-kit";
import { UserInList } from "@components";
import { Box, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AxiosError } from "axios";
import { useSwiper } from "swiper/react";

import { useDonationContext } from "@features/donation/DonationContext";

import { useSession } from "@contexts";

import { getParsedUserName, handleErrorToast } from "@lib/helpers";
import { ErrorUnprocessable, Tier, User } from "@lib/types";

import classes from "./TiersListModal.module.scss";

interface Props {
  tier: Tier;
  onClose: () => void;
  isOpen: boolean;
  owner?: User;
  setSuccessOpen?: (open: boolean) => void;
  idx?: number;
}

export const TierInModal: FC<Props> = ({ tier, onClose, isOpen, setSuccessOpen, idx }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useSession();
  const { activeIndex } = useSwiper();
  const { startDonation, clientSecret, isLoading, setLoading } = useDonationContext();

  const onPaymentAccept = async () => {
    if (!stripe || !elements) {
      return;
    }

    try {
      setLoading(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: user?.email,
              name: user?.display_name,
              address: {
                country: user?.address?.country?.uuid,
                postal_code: user?.address?.postal_code,
              },
            },
          },
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          handleErrorToast(error.message || "An error occurred.");
        } else {
          handleErrorToast("An unexpected error occurred.");
        }
      } else {
        onClose();

        if (setSuccessOpen) {
          setSuccessOpen(true);
        }
      }
    } catch (err) {
      handleErrorToast((err as AxiosError).response?.data as ErrorUnprocessable);
    } finally {
      setLoading(false);
    }
  };

  useEffectAsync(
    async (awaiter) => {
      if (isOpen && idx === activeIndex) {
        await awaiter(
          startDonation({
            campaign_id: tier.uuid,
            anonymous: false,
            amount: tier.target_amount ?? 1,
          }) as Promise<void>,
        );
      }
    },
    [isOpen],
  );

  if (!clientSecret || isLoading) {
    return (
      <Stack sx={{ width: "100%", minHeight: "300px" }}>
        <Box sx={{ m: "auto" }}>
          <Preloader />
        </Box>
      </Stack>
    );
  }

  return (
    <>
      <DialogContent>
        <Stack className={classes.wrapper}>
          <Stack className={classes.inner}>
            <Stack className={classes.tier__wrapper}>
              <Stack className={classes.tier__heading}>
                <Icon
                  src={tier.tier_image?.length ? tier.tier_image : "/images/logo-short.svg"}
                  alt="tier reward"
                  style={{ objectFit: "cover" }}
                  width={140}
                  height={142}
                  priority
                />
                <Stack className={classes.info}>
                  <Stack gap="15px">
                    <Typography className={classes.info__title}>Support</Typography>
                    <Typography
                      className={classes.info__donation}
                    >{`$${tier.target_amount ?? 1} per month`}</Typography>
                  </Stack>
                  {!!tier.creators?.length && (
                    <Stack gap="15px">
                      <Typography className={classes.info__title}>Creator(s)</Typography>
                      <Stack gap="15px">
                        {tier.creators?.map((creator) => (
                          <UserInList
                            key={creator.uuid}
                            name={getParsedUserName(creator)}
                            image={creator.image as string}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                  {!!tier.recipients?.length && (
                    <Stack gap="15px">
                      <Typography className={classes.info__title}>Supporting</Typography>
                      <Stack gap="15px">
                        {tier.recipients?.map((recipient) => (
                          <UserInList
                            key={recipient.uuid}
                            name={getParsedUserName(recipient)}
                            image={recipient.image as string}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              {!!tier.reward_description && (
                <Typography className={classes.tier__description}>
                  {tier.reward_description}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack className={classes.payment}>
            <Typography className={classes.payment__title}>
              Select Recurring Payment Method
            </Typography>
            <Box className={classes.payment__body}>
              <PaymentElement
                id="payment-element"
                options={{
                  layout: "auto",
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 0, bottom: 0 }}>
        <Stack flexDirection="row" className={classes.footer}>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <GradientButton onClick={onPaymentAccept} className={classes.submitBtn}>
            Support
          </GradientButton>
        </Stack>
      </DialogActions>
    </>
  );
};
