import { FC } from "react";

import { GradientButton, Icon, SecondaryButton } from "@causevest/ui-kit";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { Campaign } from "@lib/types";

import { CreatorAvatar } from "../";
import classes from "./AboutCreator.module.scss";
import { UserInfo } from "./UserInfo";

interface Props {
  campaign: Campaign;
}

export const AboutCreator: FC<Props> = ({ campaign }) => {
  const userAddress = campaign.owner.address
    ? `${campaign.owner.address.administrative_area} ${campaign.owner.address.nationality}`.trim()
    : "";

  const userHaveInfo = !!campaign.owner.occupation || !!campaign.owner.address;

  return (
    <Stack className={classes.wrapper}>
      <Stack alignItems="center" className={classes.inner}>
        <Stack flexDirection="row" alignItems="center" gap="20px">
          <CreatorAvatar campaign={campaign} isBig />
          <Box className={classes.mobileUserInfo}>
            <UserInfo owner={campaign.owner} />
          </Box>
        </Stack>
        <Stack className={classes.right}>
          <Box className={classes.desktopUserInfo}>
            <UserInfo owner={campaign.owner} />
          </Box>
          <Stack className={classes.info}>
            {userHaveInfo && (
              <Stack className={classes.contacts}>
                {!!campaign.owner.occupation && (
                  <Typography>{campaign.owner.occupation}</Typography>
                )}
                {!!campaign.owner.address && <Typography>{userAddress}</Typography>}
              </Stack>
            )}
            {campaign.owner.biography ? (
              <Typography className={classes.description}>{campaign.owner.biography}</Typography>
            ) : (
              <Typography className={classes.description}>{campaign.short_description}</Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between" className={classes.footer}>
        <Stack flexDirection="row" className={classes.footer__left}>
          <SecondaryButton className={classes.footer__btn}>Follow</SecondaryButton>
          <GradientButton className={classes.footer__btn}>
            <Icon width={16} height={14} src="/images/share.svg" alt="share icon" />
            <span>Share</span>
          </GradientButton>
        </Stack>
        <GradientButton className={clsx(classes.footer__btn, classes._support)}>
          Support
        </GradientButton>
      </Stack>
    </Stack>
  );
};
