"use client";

import { FC } from "react";

import { InfoBlock, SwiperSlider, useSwiperRef } from "@causevest/ui-kit";
import { UserInList } from "@components";
import { Stack } from "@mui/material";
import chunk from "lodash.chunk";

import { supportersStub } from "../mockData";
import classes from "./DonorView.module.scss";

export const SupportersSlider: FC = () => {
  const slides = chunk(supportersStub, 4);
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

  return (
    <InfoBlock title="Supporters" prevBtnRef={prevElRef} nextBtnRef={nextElRef}>
      <SwiperSlider prevEl={prevEl} nextEl={nextEl}>
        {slides.map((slide, key) => (
          <Stack key={`supporters_slide_${key}`} className={classes.supportersSlide}>
            {slide.map((item) => (
              <UserInList key={item.name} name={item.name} image={item.image} />
            ))}
          </Stack>
        ))}
      </SwiperSlider>
    </InfoBlock>
  );
};
