"use client";

import { FC, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";

import { ModalActionsField, Preloader, Switcher } from "@causevest/ui-kit";
import { DateRange } from "@components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogContent, Stack } from "@mui/material";
import pickBy from "lodash.pickby";
import * as yup from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";
import form from "@lib/styles/form.module.scss";

import { CampaignCreationModal, useCampaignContext } from ".";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPrev: () => void;
  onSubmit: (values: FieldValues) => Promise<void>;
  isLoading: boolean;
}

export const CampaignSettingsModal: FC<Props> = ({
  isOpen,
  onClose,
  onPrev,
  onSubmit,
  isLoading,
}) => {
  const { newCampaign, cleanup } = useCampaignContext();
  const [endDateIsRequired, setEndDateIsRequired] = useState<string>("false");
  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape({
        toEndCampaign: yup.string(),
        hide_balance: yup.string(),
        starts_at: yup.string().required(REQUIRED_MESSAGE),
        ends_at:
          endDateIsRequired === "true" ? yup.string().required(REQUIRED_MESSAGE) : yup.string(),
      }),
    ),
    defaultValues: {
      toEndCampaign: "false",
      hide_balance: "false",
      starts_at: "",
      ends_at: "",
    },
    progressive: true,
  });

  const { reset, handleSubmit, watch } = methods;

  const isEndDateOpen = watch("toEndCampaign");

  const onSaveData = async ({ balance, goal_type, ...values }: FieldValues) => {
    const originalValues = pickBy(values, (val) => val !== "" && val !== undefined && val !== 0);

    await onSubmit({
      ...originalValues,
      ...newCampaign,
    });
    onClose();
    reset();
    cleanup();
  };

  const onModalClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    setEndDateIsRequired(isEndDateOpen as string);
  }, [isEndDateOpen]);

  return (
    <CampaignCreationModal
      isOpen={isOpen}
      onClose={onClose}
      title="Campaign Settings"
      hint="Include additional information that will help your campaign stand out."
      stepsNum={3}
      step={2}
    >
      {isLoading ? (
        <Stack sx={{ width: "100%", height: "490px" }}>
          <Box sx={{ margin: "auto" }}>
            <Preloader />
          </Box>
        </Stack>
      ) : (
        <FormProvider {...methods}>
          <DialogContent>
            <Stack className={form.wrapper} sx={{ mt: "15px" }}>
              <Stack className={form.switchers}>
                <Switcher
                  label="I would like to end Campaign"
                  name="toEndCampaign"
                  items={["After goal has been achieved", "After set end date"]}
                  centered
                />
                <Switcher
                  label="Campaign Balance Option"
                  name="hide_balance"
                  items={["Show Balance", "Hide Balance"]}
                  centered
                />
              </Stack>
              <DateRange isRequired />
            </Stack>
          </DialogContent>
          <ModalActionsField
            onClose={onModalClose}
            onSubmit={handleSubmit(onSaveData)}
            onPrev={onPrev}
            submitBtnText="Create Campaign"
          />
        </FormProvider>
      )}
    </CampaignCreationModal>
  );
};
