"use client";

import Image from "next/image";
import Link from "next/link";

import { FC, useEffect, useRef, useState } from "react";

import { useOnClickOutside } from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import { AppRoutes, SearchResults } from "@lib/types";

import classes from "./SearchResultsPopup.module.scss";

interface Props {
  isTextInInput: boolean;
  searchResults?: Partial<SearchResults>;
}

export const SearchResultsPopup: FC<Props> = ({ searchResults, isTextInInput }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(isTextInInput);
  }, [isTextInInput]);

  if (!searchResults || !isOpen) {
    return null;
  }

  return (
    <Stack ref={ref} className={classes.resultsPopup}>
      {!!searchResults.campaigns?.length && (
        <>
          <Typography variant="h2" className={classes.resultsPopup__heading}>
            Campaigns:
          </Typography>
          <Stack className={classes.resultsPopup__block}>
            {searchResults.campaigns.map((campaign) => (
              <Link
                key={campaign.uuid}
                href={`${AppRoutes.CAMPAIGNS}/${campaign.uuid}`}
                target="_blank"
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  className={classes.resultsPopup__item}
                  onClick={() => setIsOpen(false)}
                >
                  <Image
                    src={campaign.image.length ? campaign.image : "/images/logo-short.svg"}
                    className={classes.resultsPopup__image}
                    alt="campaign pic"
                    style={{
                      objectFit: campaign.image.length ? "cover" : "contain",
                      inset: campaign.image.length ? 0 : "8px",
                    }}
                    width={30}
                    height={30}
                    priority
                  />
                  <Typography variant="h3" className={classes.resultsPopup__title}>
                    {campaign.title}
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};
