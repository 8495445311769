"use client";

import Link from "next/link";

import { FC, MouseEvent, useState } from "react";

import { stringAvatar } from "@causevest/ui-kit";
import { Avatar, Box, IconButton, Menu, MenuItem, Stack, SxProps, Typography } from "@mui/material";
import { popoverClasses } from "@mui/material/Popover";
import { googleLogout } from "@react-oauth/google";
import clsx from "clsx";

import { useSession } from "@contexts";

import { getParsedUserName } from "@lib/helpers";
import { AppRoutes, User } from "@lib/types";

import classes from "./UserMenu.module.scss";

interface Props {
  user?: Partial<User>;
  noNameArea?: boolean;
  sx?: SxProps;
}

export const UserInfo: FC<Props> = ({ user, noNameArea, sx }) => {
  const { logout } = useSession();
  const [anchorElUser, setAnchorElUser] = useState<HTMLElement | null>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const onLogOut = async () => {
    await logout();
    googleLogout();
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap="10px" sx={sx}>
      <Box sx={{ flexGrow: 0 }}>
        <IconButton onClick={handleOpenUserMenu} className={classes.menuBtn} disableRipple>
          <Avatar
            {...stringAvatar(getParsedUserName(user))}
            style={{ width: 30, height: 30, fontSize: 14, fontWeight: 500 }}
            src={user?.avatar as string}
          />
          {!noNameArea && (
            <>
              <Typography
                className={clsx(classes.menuBtn__text, {
                  [classes._active]: Boolean(anchorElUser),
                })}
              >
                {getParsedUserName(user)}
              </Typography>
              <Box className={classes.menuBtn__triangle} />
            </>
          )}
        </IconButton>
        <Menu
          sx={{
            mt: "50px",
            [`& .${popoverClasses.paper}`]: {
              borderRadius: "0 0 4px 4px",
              border: "1px solid #EEEEEE",
              boxShadow: "none",
              minWidth: "150px",
              marginRight: "-20px",
            },
          }}
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={() => {
            setAnchorElUser(null);
          }}
        >
          <Link
            key="user_menu_0"
            href={AppRoutes.DASHBOARD}
            className={classes.menuItem__ref}
            onClick={() => {
              setAnchorElUser(null);
            }}
          >
            <MenuItem className={classes.menuItem}>
              <Typography textAlign="center" className={classes.menuItem__text}>
                Dashboard
              </Typography>
            </MenuItem>
          </Link>
          <Link
            key="user_menu_1"
            href={AppRoutes.PROFILE}
            className={classes.menuItem__ref}
            onClick={() => {
              setAnchorElUser(null);
            }}
          >
            <MenuItem className={classes.menuItem}>
              <Typography textAlign="center" className={classes.menuItem__text}>
                Profile
              </Typography>
            </MenuItem>
          </Link>
          <Link
            key="user_menu_2"
            href={`${AppRoutes.PROFILE}/edit`}
            className={classes.menuItem__ref}
            onClick={() => {
              setAnchorElUser(null);
            }}
          >
            <MenuItem className={classes.menuItem}>
              <Typography textAlign="center" className={classes.menuItem__text}>
                Edit
              </Typography>
            </MenuItem>
          </Link>
          <MenuItem className={classes.menuItem} onClick={onLogOut}>
            <Typography textAlign="center" className={classes.menuItem__text}>
              Log Out
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
    </Stack>
  );
};
