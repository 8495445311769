"use client";

import { FC } from "react";

import { GradientButton } from "@causevest/ui-kit";

import { useSession } from "@contexts";

import { useCampaignContext } from "../";
import classes from "./CreateCampaignBtn.module.scss";

export const CreateCampaignBtn: FC = () => {
  const { changeModals, cleanup } = useCampaignContext();
  const { user } = useSession();

  const onCampaignCreate = () => {
    cleanup();

    if (user?.has_subscription) {
      changeModals({ isCauseChoiceOpen: true });
    } else {
      changeModals({ isCampaignTypeScreenOpen: true });
    }
  };

  return (
    <GradientButton className={classes.createBtn} onClick={onCampaignCreate} sx={{ width: "100%" }}>
      Create New Campaign
    </GradientButton>
  );
};
