"use client";

import { FC, useState } from "react";

import { useAppModalsContext } from "@app/auth";
import {
  GradientButton,
  Icon,
  InfoBlock,
  SecondaryButton,
  useElementOpen,
} from "@causevest/ui-kit";
import { Stack, Typography } from "@mui/material";

import { useSession } from "@contexts";

import { Campaign } from "@lib/types/models";

import { TiersListModal } from "../TiersListModal";
import classes from "./DonorView.module.scss";
import { SupportersSlider } from "./SupportersSlider";

interface Props {
  campaign: Partial<Campaign>;
}

export const SubscriptionAside: FC<Props> = ({ campaign }) => {
  const { open, close, isOpen } = useElementOpen(false);
  const [currentTier, setCurrentTier] = useState(0);
  const { isAuthenticated } = useSession();
  const { setLogInOpen } = useAppModalsContext();

  const onInitSupport = () => {
    if (isAuthenticated) {
      open();
    } else {
      setLogInOpen();
    }
  };

  const openCurrentTier = (idx: number) => {
    setCurrentTier(idx);
    onInitSupport();
  };

  return (
    <>
      <Stack className={classes.aside}>
        <GradientButton className={classes.supportBtn} onClick={onInitSupport}>
          Support
        </GradientButton>
        {campaign?.tiers?.map((tier, key) => (
          <InfoBlock key={`tier_${key}`} title={tier.title} innerClass={classes.tier}>
            <Icon
              src={tier.tier_image?.length ? tier.tier_image : "/images/logo-short.svg"}
              alt="tier reward"
              style={{
                objectFit: tier.tier_image?.length ? "cover" : "contain",
                inset: tier.tier_image?.length ? 0 : "0 8px",
              }}
              className={classes.tier__image}
              fill
              sizes="100%"
              priority
            />
            <Typography variant="h2" className={classes.tier__name}>
              {tier.name}
            </Typography>
            <>
              {!!tier.reward_description && (
                <Typography className={classes.tier__description}>
                  {tier.reward_description}
                </Typography>
              )}
            </>
            <SecondaryButton
              className={classes.tier__donateBtn}
              onClick={() => openCurrentTier(key)}
            >
              {`$${tier.target_amount ?? 1} per month`}
            </SecondaryButton>
          </InfoBlock>
        ))}
        <SupportersSlider />
      </Stack>
      <TiersListModal isOpen={isOpen} onClose={close} campaign={campaign} current={currentTier} />
    </>
  );
};
