import Link from "next/link";

import { FC } from "react";

import { useAppModalsContext } from "@app/auth";
import { MuiButton, stringAvatar } from "@causevest/ui-kit";
import { Avatar } from "@mui/material";

import { useSession } from "@contexts";

import { getParsedUserName } from "@lib/helpers";
import { AppRoutes } from "@lib/types";

interface Props {
  onClose: () => void;
}

export const LoggedInSection: FC<Props> = ({ onClose }) => {
  const { user } = useSession();
  const { setLogInOpen } = useAppModalsContext();

  if (!user?.uuid) {
    return (
      <MuiButton variant="outlined" style={{ fontWeight: 400 }} onClick={setLogInOpen}>
        Log In
      </MuiButton>
    );
  }

  return (
    <Link href={AppRoutes.PROFILE} style={{ textDecoration: "none" }} onClick={onClose}>
      <Avatar
        {...stringAvatar(getParsedUserName(user))}
        alt="user avatar"
        src={user?.avatar as string}
        style={{
          fontSize: "14px",
          width: 30,
          height: 30,
          fontWeight: 400,
        }}
      />
    </Link>
  );
};
