import { FC } from "react";

import { SxProps } from "@mui/material";

import { Campaign } from "@lib/types";

import { DonationContextProvider } from "./DonationContext";
import StepOneModal from "./modals/StepOneModal";
import { StepThreeModal } from "./modals/StepThreeModal";
import { StepTwoModal } from "./modals/StepTwoModal";

interface Props {
  campaign: Partial<Campaign>;
  initialAmount?: number;
  sx?: SxProps;
  className?: string;
}

export const DonationSection: FC<Props> = ({ campaign, initialAmount, ...props }) => {
  return (
    <DonationContextProvider campaign={campaign} initialAmount={initialAmount} {...props}>
      <StepOneModal campaign={campaign} />
      <StepTwoModal campaign={campaign} initialAmount={initialAmount} />
      <StepThreeModal campaign={campaign} />
    </DonationContextProvider>
  );
};
