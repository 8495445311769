import React, { FC } from "react";

import { ModalActionsField, MuiDialog } from "@causevest/ui-kit";
import { LoggedInSection, ShareSection } from "@components";
import { Stack, Typography } from "@mui/material";

import { useDonationContext } from "@features/donation/DonationContext";

import { useSession } from "@contexts";

import { Campaign } from "@lib/types";

import DonationModalAside from "./DonationModalAside";
import classes from "./StepModal.module.scss";

interface Props {
  campaign: Partial<Campaign>;
}

export const StepThreeModal: FC<Props> = ({ campaign }) => {
  const { user } = useSession();
  const { closeDonationScreens, donationBody, step } = useDonationContext();

  const postalCode = user?.address?.postal_code ?? donationBody.postal_code;
  const country = user?.address?.country?.name;
  const anonymousUserName =
    `${donationBody.first_name ?? user?.first_name ?? ""} ${donationBody.last_name ?? user?.last_name ?? ""}`.trim();
  const headingText = anonymousUserName.length
    ? `Thanks ${anonymousUserName}!`
    : `Thanks ${user?.display_name}!`;

  return (
    <MuiDialog
      isOpen={step === 2}
      handleClose={closeDonationScreens}
      title="Thanks!"
      titleActionBlock={<LoggedInSection onClose={closeDonationScreens} />}
      bottomContent={
        <ModalActionsField
          onClose={closeDonationScreens}
          onSubmit={closeDonationScreens}
          submitBtnText="Finish"
        />
      }
    >
      <Stack className={classes.wrapper}>
        <div className={classes.form}>
          <div className={classes.greetings}>
            <p className={classes.greetings__description}>
              {headingText}
              <br />
              Share to help the campaign achieve their goal!
            </p>
            <ShareSection />
          </div>
          <div className={classes.info}>
            <Typography variant="h4" className={classes.info__title}>
              Donation Details
            </Typography>
            <Stack className={classes.info__inner}>
              <Typography>{user?.display_name ?? anonymousUserName}</Typography>
              {!!(country || postalCode) && (
                <Stack flexDirection="row" gap="20px">
                  {!!country && <Typography>{country}</Typography>}
                  {!!postalCode && (
                    <Typography>
                      {user?.address?.postal_code ?? donationBody.postal_code}
                    </Typography>
                  )}
                </Stack>
              )}
              {(user?.email || donationBody.email) && (
                <Typography>{user?.email ?? donationBody.email}</Typography>
              )}
              {!!donationBody.amount && (
                <Typography>{`Total: $ ${donationBody.amount}`}</Typography>
              )}
            </Stack>
          </div>
        </div>

        <DonationModalAside campaign={campaign} />
      </Stack>
    </MuiDialog>
  );
};
