"use client";

import { useRouter } from "next/navigation";

import { useState } from "react";
import { FieldValues } from "react-hook-form";

import { useEffectAsync } from "@causevest/ui-kit";
import debounce from "lodash.debounce";

import { searchAPI } from "@api";

import { SERVER_ERROR_MESSAGE } from "@lib/constants";
import { handleErrorToast, parseSearchResults } from "@lib/helpers";
import { AppRoutes, SearchResults } from "@lib/types";

const MIN_SEARCH_LENGTH = 3;
const QUERY_GAP_MS = 500;

interface ReturnType {
  searchResults: Partial<SearchResults>;
  onSubmitSearch: (values: FieldValues) => void;
  isTextInside: boolean;
  isLoading: boolean;
}

export const useSearch = (watch: (text: string) => string, fieldName = "search"): ReturnType => {
  const router = useRouter();
  const [searchResults, setSearchResults] = useState<Partial<SearchResults>>({});
  const [isLoading, setIsLoading] = useState(false);
  const searchString = watch(fieldName);
  const isTextInside = searchString ? searchString.length > MIN_SEARCH_LENGTH : false;

  const onEnterText = debounce(async (text: string) => {
    try {
      setIsLoading(true);
      const result = await searchAPI.globalSearch({ params: { q: text, limit: 2 } });
      const sortedResult = parseSearchResults(result);
      setSearchResults(sortedResult);
    } catch (e) {
      handleErrorToast(SERVER_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  }, QUERY_GAP_MS);

  const onSubmitSearch = ({ search }: FieldValues) => {
    router.push(`${AppRoutes.SEARCH}?q=${search}`);
  };

  useEffectAsync(
    async (awaiter) => {
      if (isTextInside) {
        await awaiter(onEnterText(searchString) as Promise<void>);
      }
    },
    [searchString, isTextInside],
  );

  return {
    onSubmitSearch,
    searchResults,
    isTextInside,
    isLoading,
  };
};
