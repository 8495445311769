import { FC } from "react";
import { FieldValues, FormProvider } from "react-hook-form";

import { ModalActionsField } from "@causevest/ui-kit";
import { DialogContent } from "@mui/material";

import { useCommonDataContext } from "@contexts";

import { CampaignCreationModal, GoalTypesForm, useCampaignContext, useGoalTypes } from "../";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onPrev: () => void;
}

export const CampaignFundsModal: FC<Props> = ({ isOpen, onClose, onSubmit, onPrev }) => {
  const { newCampaign, setCampaignData } = useCampaignContext();
  const { currencies } = useCommonDataContext();
  const methods = useGoalTypes({
    currency: newCampaign?.currency ?? currencies[0].uuid,
    maximum_amount: newCampaign?.maximum_amount,
    recommended_amount: newCampaign?.recommended_amount,
    minimum_amount: newCampaign?.minimum_amount,
  });
  const { handleSubmit, reset } = methods;

  const onSaveData = (values: FieldValues) => {
    setCampaignData(values);
    onSubmit();
  };

  const onModalClose = () => {
    reset();
    onClose();
  };

  return (
    <CampaignCreationModal
      isOpen={isOpen}
      onClose={onModalClose}
      title="Fund Settings"
      hint={
        newCampaign?.is_subscription
          ? "Looking for regular monthly donations?"
          : "Race to the finish line with the help of your supporters"
      }
      stepsNum={3}
      step={1}
    >
      <FormProvider {...methods}>
        <DialogContent>
          <GoalTypesForm currencies={currencies} sx={{ mt: "20px" }} />
        </DialogContent>
        <ModalActionsField
          onClose={onModalClose}
          onSubmit={handleSubmit(onSaveData)}
          onPrev={onPrev}
        />
      </FormProvider>
    </CampaignCreationModal>
  );
};
