"use client";

import { UseFormReturn, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { REQUIRED_MESSAGE } from "@lib/constants";
import { CampaignGoal, Tier } from "@lib/types";

interface ConditionalTypes {
  name: string;
  reward_description: string;
  target_amount: number;
  tier_image: string;
  currency: string;
  type: CampaignGoal;
  starts_at?: string;
  ends_at?: string;
}

interface ReturnType {
  methods: UseFormReturn<Omit<ConditionalTypes, "type">>;
}

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_MESSAGE),
  reward_description: yup.string().required(REQUIRED_MESSAGE),
  target_amount: yup.number().required(REQUIRED_MESSAGE).min(1, REQUIRED_MESSAGE),
  currency: yup.string().required(REQUIRED_MESSAGE),
  tier_image: yup.string().required(REQUIRED_MESSAGE),
  is_subscription: yup.boolean(),
  type: yup.string(),
});

export const useTierCreationForm = (currency: string, tier?: Partial<Tier>): ReturnType => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: tier?.name ?? "",
      reward_description: tier?.reward_description ?? "",
      target_amount: tier?.target_amount,
      currency: tier?.currency?.uuid ?? currency,
      tier_image: tier?.tier_image ?? "",
      is_subscription: true,
      type: "open-ended",
    },
    progressive: true,
  });

  return {
    methods,
  };
};
