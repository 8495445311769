import Link from "next/link";
import { usePathname } from "next/navigation";

import React, { FC } from "react";

import { MuiButton } from "@causevest/ui-kit";
import { Box, Container } from "@mui/material";

import { useSession } from "@contexts";

import { AppRoutes } from "@lib/types";

import classes from "./HeaderTabsMenu.module.scss";

export const HeaderTabsMenu: FC = () => {
  const pathname = usePathname();
  const { isAuthenticated } = useSession();

  if (
    !isAuthenticated ||
    (!pathname.includes(AppRoutes.PROFILE) && !pathname.includes(AppRoutes.DASHBOARD))
  ) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <Container className={classes.inner}>
        <Link href={AppRoutes.DASHBOARD} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Home</MuiButton>
        </Link>
        <Link href={AppRoutes.MAIN} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Performance</MuiButton>
        </Link>
        <Link href={AppRoutes.MAIN} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Campaigns</MuiButton>
        </Link>
        <Link href={AppRoutes.MAIN} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Donations</MuiButton>
        </Link>
        <Link href={AppRoutes.MAIN} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Disputes</MuiButton>
        </Link>
        <Link href={AppRoutes.MAIN} style={{ textDecoration: "none" }} passHref>
          <MuiButton variant="text">Funds</MuiButton>
        </Link>
      </Container>
    </Box>
  );
};
