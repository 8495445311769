"use client";

import { FC } from "react";

import { CausesList } from "@lib/types";

import { CampaignProvider, CreateCampaignBtn, NewCampaignModals } from ".";

interface Props {
  causes: CausesList;
}

export const CreateCampaignSection: FC<Props> = (props) => (
  <CampaignProvider>
    <CreateCampaignBtn />
    <NewCampaignModals {...props} />
  </CampaignProvider>
);
