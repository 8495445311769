"use client";

import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { MuiButton, TextInput } from "@causevest/ui-kit";
import { SearchResultsPopup, useSearch } from "@components";
import { Box, InputAdornment } from "@mui/material";
import clsx from "clsx";

import { SearchResults } from "@lib/types";

import classes from "./SearchInputSmall.module.scss";

interface Props {
  searchCauses?: boolean;
  onChange?: (results: Partial<SearchResults>) => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export const SearchInputSmall: FC<Props> = ({ searchCauses, onChange, onCancel, disabled }) => {
  const methods = useForm({
    defaultValues: {
      search: "",
    },
  });
  const { handleSubmit, watch } = methods;
  const { isTextInside, searchResults, onSubmitSearch } = useSearch(watch);

  const placeholder = searchCauses ? "Search for Causes" : "Search for a Cause or category";

  useEffect(() => {
    if (isTextInside && searchResults && onChange) {
      onChange(searchResults);
    }
  }, [isTextInside, searchResults]); // eslint-disable-line

  useEffect(() => {
    if (onCancel && !isTextInside) {
      onCancel();
    }
  }, [isTextInside]); // eslint-disable-line

  return (
    <FormProvider {...methods}>
      <Box className={classes.wrapper}>
        <TextInput
          name="search"
          type="search"
          className={clsx(
            classes.input,
            { [classes._long]: searchCauses },
            { [classes._active]: isTextInside },
          )}
          iconUrl="/images/search.svg"
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          InputProps={{
            endAdornment:
              !searchCauses && isTextInside ? (
                <InputAdornment position="end">
                  <MuiButton variant="outlined" size="small" onClick={handleSubmit(onSubmitSearch)}>
                    Search
                  </MuiButton>
                </InputAdornment>
              ) : null,
          }}
        />
        {!searchCauses && (
          <SearchResultsPopup searchResults={searchResults} isTextInInput={isTextInside} />
        )}
      </Box>
    </FormProvider>
  );
};
