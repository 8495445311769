"use client";

import Image from "next/image";
import Link from "next/link";

import { FC } from "react";

import { Icon, MuiButton, Timing } from "@causevest/ui-kit";
import { FundsBar } from "@components";
import { Box, Stack, Typography } from "@mui/material";
import clsx from "clsx";

import { AppRoutes, Campaign } from "@lib/types";

import classes from "./EntityCard.module.scss";

interface Props {
  campaign: Campaign;
  className?: string;
}

export const EntityCard: FC<Props> = ({ campaign, className }) => (
  <Link href={`${AppRoutes.CAMPAIGNS}/${campaign.uuid}`} className={classes.wrapperLink}>
    <Box
      role="article"
      className={clsx(
        classes.wrapper,
        { [classes._isSubscription]: campaign.is_subscription },
        className,
      )}
    >
      <Box className={clsx(classes.img, { [classes._liked]: !!campaign.votes })}>
        <Image
          src={campaign.image.length ? campaign.image : "/images/logo-short.svg"}
          alt="campaign pic"
          style={{
            objectFit: campaign.image.length ? "cover" : "contain",
            inset: campaign.image.length ? 0 : "8px",
          }}
          sizes="100%"
          priority
          fill
        />
        {!!campaign.category && (
          <Box className={classes.status}>{campaign.category.name ?? campaign.category}</Box>
        )}
        {!!campaign.votes && (
          <Stack
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.imgInfo}
          >
            <Stack flexDirection="row" alignItems="center" className={classes.imgInfo__item}>
              <Icon src="/images/like.svg" alt="likes" width={14} height={14} />
              <Link href={AppRoutes.MAIN}>{`${campaign.votes} Likes`}</Link>
            </Stack>
          </Stack>
        )}
      </Box>
      <Stack className={classes.inner}>
        <Stack sx={{ m: "auto" }}>
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h2" className={classes.title}>
              {campaign.title}
            </Typography>
            {campaign.is_trusted && (
              <Icon src="/images/badge.svg" alt="badge" width={15} height={15} />
            )}
          </Stack>
          <Typography className={classes.text}>{campaign.short_description}</Typography>
          {campaign.is_subscription ? (
            <Box className={classes.creator}>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                className={classes.creator__info}
              >
                <Typography>
                  <strong>{campaign.donor_count}</strong>&nbsp;subscribers
                </Typography>
                <Typography>
                  <strong>$1</strong>&nbsp;per months
                </Typography>
              </Stack>
              <MuiButton variant="outlined" className={classes.creator__support} fullWidth>
                Support
              </MuiButton>
            </Box>
          ) : (
            <>
              {!!campaign.goal && (
                <Box sx={{ mt: "20px" }}>
                  <FundsBar goal={campaign.goal} />
                </Box>
              )}
              {!!campaign.donor_count && (
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.bottom}
                >
                  <Typography
                    className={classes.donors}
                  >{`${campaign.donor_count} Donors`}</Typography>
                  <Timing label="1 Day Ago" />
                </Stack>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  </Link>
);
