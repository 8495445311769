"use client";

import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { proxyAPIGateway } from "@api";

import { handleErrorToast } from "@lib/helpers";
import { AdvancedAxiosConfig, ErrorUnprocessable } from "@lib/types";

import { useCampaignOptimisticUpdate } from "../";

type CallbackType = <T, R>(values?: T, options?: AdvancedAxiosConfig) => Promise<R>;

interface ReturnType<T> {
  isLoading: boolean;
  updateCampaign: CallbackType;
  result?: T;
}

export const useUpdateCampaign = <T, R>(
  method: "post" | "patch" | "delete",
  url: string,
  successMessage: string | false = "Campaign was successfully updated",
): ReturnType<R> => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<R>();
  const handleInvalidation = useCampaignOptimisticUpdate();

  const updateCampaign = useCallback(
    async (values?: T) => {
      try {
        setIsLoading(true);

        if (method === "delete") {
          await proxyAPIGateway.delete(url);
        } else {
          const res = await proxyAPIGateway[method](url, values);

          setResult(res.data);
        }

        await handleInvalidation();

        if (successMessage) {
          toast.success(successMessage, {
            theme: "colored",
          });
        }
      } catch (error) {
        handleErrorToast((error as AxiosError).response?.data as ErrorUnprocessable);
      } finally {
        setIsLoading(false);
      }
    },
    [handleInvalidation, method, successMessage, url],
  ) as CallbackType;

  return {
    isLoading,
    result,
    updateCampaign,
  };
};
