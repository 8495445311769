"use client";

import Link from "next/link";

import React, { FC, useState } from "react";

import { useAppModalsContext } from "@app/auth";
import { Icon } from "@causevest/ui-kit";
import { SearchInputSmall } from "@components";
import { Box, Container, LinearProgress, Stack } from "@mui/material";
import clsx from "clsx";

import { useSession } from "@contexts";

import { AppRoutes } from "@lib/types";

import { HeaderMenu } from "./HeaderMenu";
import { HeaderTabsMenu } from "./HeaderTabsMenu";
import { MobileHeaderMenu } from "./MobileHeaderMenu";
import classes from "./header.module.scss";

export const Header: FC = () => {
  const { isLoading } = useSession();
  const { setSignUpOpen, setLogInOpen } = useAppModalsContext();
  const [isMobileSearchOpen, setMobileSearchOpen] = useState<boolean>(false);

  return (
    <>
      <Stack justifyContent="space-around" className={classes.header}>
        <Container>
          <Stack
            className={classes.header__inner}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {!isMobileSearchOpen && (
              <Stack flexDirection="row" alignItems="center" className={classes.left}>
                <Link
                  href={AppRoutes.MAIN}
                  className={clsx(classes.header__logo, classes._desktop)}
                >
                  <Icon src="/images/logo.svg" width={168} height={40} alt="logo" />
                </Link>
                <Link href={AppRoutes.MAIN} className={clsx(classes.header__logo, classes._mobile)}>
                  <Icon src="/images/logo.svg" width={137} height={32} alt="logo" />
                </Link>
                <Box className={classes.header__search}>
                  <SearchInputSmall />
                </Box>
              </Stack>
            )}
            <Box className={clsx(classes.header__menu, classes._mobile)}>
              {isLoading ? (
                <Box sx={{ width: "100px" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <MobileHeaderMenu
                  setLogInOpen={setLogInOpen}
                  setSignUpOpen={setSignUpOpen}
                  handleSearchOpen={setMobileSearchOpen}
                  isSearchOpen={isMobileSearchOpen}
                />
              )}
            </Box>
            <Box className={clsx(classes.header__menu, classes._desktop)}>
              <HeaderMenu setLogInOpen={setLogInOpen} setSignUpOpen={setSignUpOpen} />
            </Box>
          </Stack>
        </Container>
        <HeaderTabsMenu />
      </Stack>
    </>
  );
};
