"use client";

import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { SearchResultsPopup, useSearch } from "@/src/components";
import { GradientButton, TextInput } from "@causevest/ui-kit";
import { Search } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import clsx from "clsx";

import classes from "./SearchInput.module.scss";

interface Props {
  placeholder?: string;
  closeMobileSearch?: (isOpen: boolean) => void;
  isAdaptive?: boolean;
}

export const SearchInput: FC<Props> = ({
  placeholder = "Search for causes and campaigns",
  closeMobileSearch,
  isAdaptive,
}) => {
  const methods = useForm();
  const { isTextInside, searchResults, onSubmitSearch } = useSearch(methods.watch);

  return (
    <FormProvider {...methods}>
      <Box
        sx={{ position: "relative" }}
        className={clsx(classes.wrapper, {
          [classes._isMobile]: !!closeMobileSearch,
          [classes._isAdaptive]: isAdaptive,
        })}
      >
        <TextInput
          name="search"
          type="search"
          className={clsx(classes.input, {
            [classes._isMobile]: !!closeMobileSearch,
            [classes._isAdaptive]: isAdaptive,
          })}
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <GradientButton
                  className={clsx(classes.button, {
                    [classes._isMobile]: !!closeMobileSearch,
                    [classes._isAdaptive]: isAdaptive,
                  })}
                  onClick={methods.handleSubmit(onSubmitSearch)}
                >
                  {closeMobileSearch ? (
                    <Search sx={{ color: "#ffffff", mt: "3px" }} fontSize="medium" />
                  ) : (
                    "Search"
                  )}
                </GradientButton>
              </InputAdornment>
            ),
          }}
        />
        <SearchResultsPopup searchResults={searchResults} isTextInInput={isTextInside} />
      </Box>
    </FormProvider>
  );
};
