import React, { FC, MouseEvent, useState } from "react";

import { SearchInput } from "@components";
import { Close, Search } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Menu, Stack } from "@mui/material";

import { useSession } from "@contexts";

import { HeaderMenu } from "./HeaderMenu";
import { UserInfo } from "./UserInfo";

interface Props {
  setLogInOpen: () => void;
  setSignUpOpen: () => void;
  handleSearchOpen: (isOpen: boolean) => void;
  isSearchOpen: boolean;
}

export const MobileHeaderMenu: FC<Props> = ({
  setLogInOpen,
  setSignUpOpen,
  handleSearchOpen,
  isSearchOpen,
}) => {
  const { isAuthenticated } = useSession();
  const { user } = useSession();
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  return (
    <>
      {isSearchOpen ? (
        <Stack flexDirection="row" justifyContent="space-between">
          <SearchInput closeMobileSearch={() => handleSearchOpen(false)} />
          <IconButton onClick={() => handleSearchOpen(false)} sx={{ minWidth: "44px" }}>
            <Close fontSize="medium" />
          </IconButton>
        </Stack>
      ) : (
        <Stack flexDirection="row" alignItems="center">
          <IconButton onClick={() => handleSearchOpen(true)}>
            <Search color="primary" fontSize="small" />
          </IconButton>
          <Box>
            <IconButton onClick={handleOpenMenu}>
              <MenuIcon fontSize="medium" />
            </IconButton>
            <Menu
              sx={{ mt: "45px", width: "200px" }}
              id="menu-appbar"
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={!!anchorElMenu}
              onClose={() => setAnchorElMenu(null)}
            >
              <HeaderMenu
                setLogInOpen={setLogInOpen}
                setSignUpOpen={setSignUpOpen}
                onClick={() => setAnchorElMenu(null)}
                isMobile
              />
            </Menu>
          </Box>
          {isAuthenticated && <UserInfo user={user} sx={{ ml: "6px" }} noNameArea />}
        </Stack>
      )}
    </>
  );
};
